
$(document).ready(function(){




  //-----------------------General Animations and tweaks----------------------------
	$(window).load(function(){
	   $("#loader").fadeOut();
	});
	
  $('.ui.dropdown').dropdown();

  $('.message .close').on('click', function() {
      $(this).closest('.message').transition('fade');
    });


  jQuery("*").find("a[href='"+window.location.href+"']").each(function(){        
    jQuery(this).addClass("active");});

  $('.activating.element').popup();
  $('.ui.accordion').accordion();
//-------------------------------------------------------------------



$('.ui.sidebar').sidebar('attach events', '.toc.item');

$(window).on('resize', function(){
      var win = $(this);
      if (win.width() < 764) { 

      $('#respondButton').addClass('fluid');
      $('.searchButton').addClass('fluid');
      $('.profilePic').addClass('fluid');
      $('#loader').hide();
      }
    else
    {
        $('#respondButton').removeClass('fluid');
        $('.searchButton').removeClass('fluid');
        $('.profilePic').removeClass('fluid');
        $('#loader').show();
    }
});

$(window).load(function(){
      if ($(window).width() < 764) {
       $('#respondButton').addClass('fluid');
       $('.searchButton').addClass('fluid');
       $('#loader').hide();
      }
    });



//-----------------------------Tutoring Request-------------------------
$('.request.modal').modal('setting', 'transition', 'fade up')
  .modal('attach events', '.request.button', 'show')
  .modal('attach events', '.cancel.button', 'hide');
  
  $(".cancel").click(function(){
    $("#location").val("");
    $("#language1").val("Select a language");
    $("#language2").val("Select a language");
    $("#message").val("");
    $("input[name='language1']").val("Select a language");
    $("input[name='language2']").val("Select a language");
  });

//------------------------------------------------------------------------
	




//--------------------------------Form Validation---------------------------------------
$("#username").focusout(function(e){
    checkUser();
  });



$("#email").focusout(function(e){
   checkEmail();
  });



  //----------------------------------------
  $('.language1').click(function(e){
    $("#language1").fadeOut().remove();
    $(this).removeClass('error');
  });

  $(".language2").click(function(){
    $("#language2").fadeOut().remove();
    $(this).removeClass("error");
  });
  //-------------------------------------------


  	 $('.ui.form').form({
          inline : true,
          on     : 'blur',
          fields: {



            username: {
              identifier  : 'username',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter your username'
                }
              ]
            },



            email: {
              identifier  : 'email',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter your e-mail'
                },
                {
                  type   : 'email',
                  prompt : 'Please enter a valid e-mail'
                }
              ]
            },


            password: {
              identifier  : 'password',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter your password'
                },
                {
                  type   : 'length[6]',
                  prompt : 'Your password must be at least 6 characters'
                }
              ]
            },

            password_confirmation: {
              identifier  : 'password_confirmation',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please confirm password'
                },
                {
                  type   : 'match[password]',
                  prompt : 'Passwords dont match'
                }
              ]
            },


            country: {
              identifier  : 'country',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please select a country'
                }
              ]
            },





            language1: {
              identifier  : 'language1',
              rules: [
                {
                  type   : 'different[language2]',
                  prompt : 'Languages must be different'
                }
              ]
            },



            language2: {
              identifier  : 'language2',
              rules: [
                {
                  type   : 'different[language1]',
                  prompt : 'Languages must be different'
                }
              ]
            }


          }
        });	
    
});




function checkUser()
{
  var username = $("#username").val();
    var token = $("input[name=_token]");
   

              $.ajax({
                type: "POST",
                url: "/auth/signup/?_token=" + token.val(),
                data: {
                    username: username
                },
                dataType: 'json',
                success: function(data) {
                    //
                },



                error: function(xhr) {
                  if(xhr.status == 422) {
                   
                    var errors = xhr.responseJSON;
                    $.each( errors, function( key, val ) {
                       console.log(key+ ':' + val);

                       if(key === 'username'){
                        $(".username").addClass('error');
                        $('#usernamemessage').transition('drop').show();
                        $('#usernamemessage').html(val);
                       }
                    });


                  }
                }
          });
}


function checkEmail()
{

   var email = $("#email").val();
    var token = $("input[name=_token]");
   

              $.ajax({
                type: "POST",
                url: "/auth/signup/?_token=" + token.val(),
                data: {
                    email: email
                },
                dataType: 'json',
                success: function(data) {
                    //
                },



                error: function(xhr) {
                  if(xhr.status == 422) {
                   
                    var errors = xhr.responseJSON;
                    $.each( errors, function( key, val ) {
                       console.log(key+ ':' + val);

                       if(key === 'email'){
                        $(".email").addClass('error');
                        $('#emailmessage').transition('drop').show();
                        $('#emailmessage').html(val);
                       }
                    });


                  }
                }
          });
}


